<template>
  <a-modal
      :title="tests"
      :visible="disabledChange"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      okText="确认"
      cancelText="取消"
      @ok="handleOk"
      @cancel="handleCancel"
  >
    <div class="oneDiv">
      <p>角色名称：</p>
      <a-input class="input" v-model="oneData.name"/>
    </div>
    <div class="oneDiv">
      <p>状态：</p>
      <a-radio-group name="radioGroup" :default-value="1" @change="getChoose">
        <a-radio :value="1">
          启用
        </a-radio>
        <a-radio :value="0">
          禁用
        </a-radio>
      </a-radio-group>
    </div>
    <div class="oneDiv">
      <p>菜单权限：</p>
      <div style="border: 1px gray solid;width: 300px;height: 350px;overflow-y: auto;">
        <a-tree
            v-model="oneData.menuIds"
            checkable
            :expanded-keys="expandedKeys"
            :auto-expand-parent="autoExpandParent"
            :selected-keys="selectedKeys"
            :tree-data="menuTreeList"
            @expand="onExpand"
            @check="onCheck"
            @select="onSelect"
        />
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  inject: ['reload'],
  name: "roleChange",
  data() {
    return {
      confirmLoading: false,
      formData:{
        status:"0",
      },
      options: [],
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
    };
  },
  mounted() {
    this.$store.dispatch('getMenuLists',window.localStorage.getItem('username'))
  },
  computed:{
    menuTreeList(){
      let list = this.$store.state.menu.menuTreeList
      return this.setMenuTree(list,0)
    },
  },
  props:['disabledChange',"oneData","tests"],
  methods: {
    setMenuTree(list,pid){
      return list.filter(item=>item.pid===pid).
      sort((a,b)=> a.menuSort - b.menuSort).
      map(
          item=>{
            if(this.setMenuTree(list, item.menuId).length > 0){
              return {...item, title:item.title,key:item.menuId,children: this.setMenuTree(list, item.menuId)}
            }else{
              return {...item,title:item.title,key:item.menuId}
            }
          }
      )
    },
   async handleOk() {
      if(this.tests === '查看角色') {
        this.$emit('changeStutes',false)
      } else {
        // 修改提交
        this.formData = {
           roleId: this.oneData.roleId,
           name: this.oneData.name, //角色名称
           menuIds: this.oneData.menuIds, //菜单权限
           enabled: this.oneData.enabled //状态
        }
        const res = await this.$axios.put(this.api+'/role/update',this.formData)
         if(res.code === 200){
                this.$emit('changeStutes',false)
                this.$message.success('添加成功')
                this.$store.dispatch('getMenuLists',window.localStorage.getItem('username'))
                this.formData = {
                  status:"0"
                }
                this.reload()
              }
      }
    },
    handleCancel(e) {
      this.$emit('changeStutes',false)
      this.formData = {}
    },
    //选择器
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys) {
      // console.log('onCheck', checkedKeys);
      this.checkedKeys = checkedKeys;
      this.formData.menuIds = checkedKeys
    },
    onSelect(selectedKeys, info) {
      // console.log('onSelect', info);
      this.selectedKeys = selectedKeys;
    },
    //状态选择器
    getChoose(e){
      this.oneData.enabled=e.target.value
    }
  },
}
</script>

<style scoped>
.input{
  width: 200px;
}
.oneDiv{
  display: flex;
  font-size: 14px;
  margin-bottom: 10px;
}
.oneDiv >p{
  width: 100px;
}
</style>
