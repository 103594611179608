<template>
	<div class="table">
		<div class="header">
			<div class="list">
				<div class="item" :style="{'width':item.width+'%'}" v-for="(item, index) in columns" :key="index">
					{{item.title}}
				</div>
			</div>
		</div>
		<div class="table_body">
			<div class="body" v-for="item in list" :key="item.treeId">
				<div class="list">
					<div class="item" style="width:25%;">
						{{item.name}}
					</div>
					<div class="item" style="width:25% ;">
						 {{ item.enabled ? "启用" : "禁用" }}
					</div>
					<div class="item" style="width:25% ;">
						{{ item.createTime}}
					</div>
					<div class="item" style="width:25% ;">
						<a-button type="primary" @click="handleSee(item)">查看</a-button>
						<a-button type="primary" style="margin: 0 20px;" @click="showChangeModal(item)">修改</a-button>
            <a-popconfirm
				       	style="display: flex; align-items: center"
				       	title="确认删除"
				       	placement="bottom"
				       	ok-text="确认"
				       	cancel-text="取消"
				       	@confirm="deleteData(item.roleId)"
				       >
						   <a-button type="danger">删除</a-button>
              </a-popconfirm>
					</div>
				</div>
			</div>
		</div>
    <!-- 修改角色 -->
    <roleChange
        :disabledChange="disabledChange"
        :oneData="oneData"
        :tests="tests"
        @changeStutes="changeStutes"
    ></roleChange>
	</div>
</template>

<script>
import roleChange from "./roleChange";
	export default{
    inject: ['reload'],
		props:{
			list:Array
		},
    components:{
      roleChange,
  },
		data(){
			return {
				columns:[
				  {
					  title: '角色名称',
				    dataIndex: 'name',
				    key: 'name',
					  width:'25'
				  },
				  {
				    title: '状态',
				    dataIndex: 'age',
				    key: 'age',
					  width:'25'
				  },
				  {
				    title: '创建时间',
				    dataIndex: 'address',
				    key: 'address',
					width:'25'
				  },
				  {
				    title: '操作',
				    key: 'action',
					  dataIndex: 'tags',
					  width:'25'
				  },
				
				],
        oneData:{},
        disabledChange:false,
        tests: '',
			}
		},
		methods:{
			handleDetail(){
				this.$router.push('/except/detail')
			},
			handleCancel(){
				this.visible=false
			},
      // 修改
			showChangeModal(record){
        this.tests = '修改角色'
        this.disabledChange = true
        this.oneData = record
			},
      changeStutes(){
      this.disabledChange = false
    },
    // 删除
		 deleteData(roleId){
      this.$axios.delete(this.api+'/role/delete/' + roleId).then(res=>{
        if(res.code === 200){
          this.$message.success('删除成功')
          this.reload()
        }else{
          this.$message.error('删除失败')
        }
      })
    },
      // 查看
			handleSee(record){
        this.disabledChange = true
        this.oneData = record
        this.tests = '查看角色'
			}
		}
	}
</script>

<style scoped>
	.table{
		width: 100%;
		height: 100%;
		color: #fff;
		/* background-color: red; */
	}
	.yuan{
		width: 5px;
		height: 5px;
		border-radius: 50%;
		background-color: red;
		margin-right: 5px;
	}
	.item1>div{
		display: flex;
	}
	.yi>div,
	.wei>div{
		margin-right:10px;
		width: 4em;
	}
	.wei>div:hover{
		cursor: pointer;
	}
	.duoxuan{
		width: 40px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		
	}
	.duoxuan>div{
		width: 10px;
		height: 10px;
		border: 1px solid #ccc;
		background-color: #fff;
	}
	.header{
		display:flex;
		height: 50px;
		background-color:#1f325e ;
		border-bottom: 1px solid #005ea1;
		/* align-items: center; */
	}
	.header .list{
		background-color:#1f325e ;
		width: calc(100% - 40px);
		display: flex;
		align-items: center;
	}
	.table_body{
		height: calc(100% - 50px);
		overflow-y: auto;
	}
	.table_body .body{
		display:flex;
		min-height: 50px;
		border-bottom: 1px solid #005ea1;
	}
	.table_body .list{
		width: calc(100% - 40px);
		display: flex;
		align-items: center;
	}
	.item{
		display: flex;
		align-items: center;
		padding: 0 20px;
	}
	.model_item{
		display: flex;
		line-height: 3em;
		
	}
/* 	.model_item1{
		width:100%;
		justify-content: center;
	} */
</style>
