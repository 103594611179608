<template>
	<a-modal
		title="新增角色"
		:visible="disabled"
		:confirm-loading="confirmLoading"
		:maskClosable="false"
		okText="确认"
		cancelText="取消"
		@ok="handleOk"
		@cancel="handleCancel"
	>
		<div class="oneDiv">
			<p>角色名称：</p>
			<a-input
				class="input"
				placeholder="请输入角色名称"
				v-model="formData.name"
			/>
		</div>
		<div class="oneDiv">
			<p>状态：</p>
			<a-radio-group name="radioGroup" @change="getChoose">
				<a-radio :value="1"> 启用 </a-radio>
				<a-radio :value="0"> 禁用 </a-radio>
			</a-radio-group>
		</div>
		<div class="oneDiv">
			<p>菜单权限：</p>
			<div
				style="
					border: 1px gray solid;
					width: 300px;
					height: 350px;
					overflow-y: auto;
				"
			>
				<a-tree
					v-model="checkedKeys"
					checkable
					:expanded-keys="expandedKeys"
					:auto-expand-parent="autoExpandParent"
					:selected-keys="selectedKeys"
					:tree-data="menuTreeList"
					@expand="onExpand"
					@check="onCheck"
					@select="onSelect"
				/>
			</div>
		</div>
	</a-modal>
</template>

<script>
export default {
	inject: ['reload'],
	name: 'roleAdd',
	data() {
		return {
			confirmLoading: false,
			formData: {
				status: '0',
				name: '',
				enabled: '',
				menuIds: [],
			},
			options: [],
			expandedKeys: [],
			autoExpandParent: true,
			checkedKeys: [],
			selectedKeys: [],
		}
	},
	mounted() {
		this.$store.dispatch(
			'getMenuLists',
			window.localStorage.getItem('username')
		)
	},
	computed: {
		menuTreeList() {
			let list = this.$store.state.menu.menuTreeList
			return this.setMenuTree(list, 0)
		},
	},
	props: ['disabled'],
	methods: {
		setMenuTree(list, pid) {
			return list
				.filter((item) => item.pid === pid)
				.sort((a, b) => a.menuSort - b.menuSort)
				.map((item) => {
					if (this.setMenuTree(list, item.menuId).length > 0) {
						return {
							...item,
							title: item.title,
							key: item.menuId,
							children: this.setMenuTree(list, item.menuId),
						}
					} else {
						return { ...item, title: item.title, key: item.menuId }
					}
				})
		},
		handleOk() {
			if (this.formData.enabled === '') {
				return this.$message.warning('请选择状态')
			} else if (this.formData.name === '') {
				return this.$message.warning('请填写名称')
			} else if (this.formData.menuIds === []) {
				return this.$message.warning('请选择权限')
			}
			// console.log(this.formData)
			this.$axios
				.post(this.api + '/role/add', this.formData)
				.then((res) => {
					console.log(res)
					if (res.code === 200) {
						this.$emit('showModal', false)
						this.$message.success('添加成功')
						this.$store.dispatch(
							'getMenuLists',
							window.localStorage.getItem('username')
						)
						this.formData = {
							status: '0',
						}
						this.reload()
					}
				})
		},
		handleCancel(e) {
			this.$emit('showModal', false)
			this.formData = {}
		},
		//选择器
		onExpand(expandedKeys) {
			console.log('onExpand', expandedKeys)
			this.expandedKeys = expandedKeys
			this.autoExpandParent = false
		},
		onCheck(checkedKeys) {
			// console.log('onCheck', checkedKeys);
			this.checkedKeys = checkedKeys
			this.formData.menuIds = checkedKeys
		},
		onSelect(selectedKeys, info) {
			// console.log('onSelect', info);
			this.selectedKeys = selectedKeys
		},
		//状态选择器
		getChoose(e) {
			this.formData.enabled = e.target.value
		},
	},
}
</script>

<style scoped>
.input {
	width: 200px;
}
.oneDiv {
	display: flex;
	font-size: 14px;
	margin-bottom: 10px;
}
.oneDiv > p {
	width: 100px;
}
</style>
