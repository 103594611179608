import { render, staticRenderFns } from "./roleAdd.vue?vue&type=template&id=40fd9672&scoped=true&"
import script from "./roleAdd.vue?vue&type=script&lang=js&"
export * from "./roleAdd.vue?vue&type=script&lang=js&"
import style0 from "./roleAdd.vue?vue&type=style&index=0&id=40fd9672&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40fd9672",
  null
  
)

export default component.exports