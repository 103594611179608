<template>
	<div class="home">
		<div class="mianbao">
			<div class="breadcrumb">
				<span style="color: #fff"> 系统设置 > </span>
				<span style="color: #016ae0"> 角色管理 </span>
			</div>
		</div>
		<div class="header">
			<a-input
				class="input"
				v-model="names"
				placeholder="请输入角色名称"
			/>
			<a-button type="primary" icon="search" @click="getProjectList"
				>查询</a-button
			>
			<a-divider type="vertical" />
			<a-button type="primary" icon="plus" @click="showModal"
				>新增角色</a-button
			>
		</div>
		<roleAdd :disabled="disabled" @showModal="getData"> </roleAdd>
		<div class="main">
			<vue-scroll :ops="ops" style="width: 100%">
				<roleTable :list="data" />
			</vue-scroll>
		</div>
	</div>
</template>

<script>
import roleAdd from './modal/roleAdd' //新增角色
import roleChange from './modal/roleChange' //查看/修改角色
import roleTable from './modal/roletable.vue' //角色列表
export default {
	name: 'role',
	data() {
		return {
			disabled: false,
			oneData: {},
			data: [], // 列表信息
			columns: [
				{
					title: '角色名称',
					dataIndex: 'role.name',
				},
				{
					title: '状态',
					dataIndex: 'role.status',
					scopedSlots: { customRender: 'status' },
				},
				{
					title: '创建时间',
					dataIndex: 'role.create_time',
					scopedSlots: { customRender: 'create_time' },
				},
				{
					title: '操作',
					scopedSlots: { customRender: 'action' },
					key: 'action',
					width: 400,
				},
			],
			names: '', //输入角色名称
			// 滚动条
			ops: {
				vuescroll: {},
				scrollPanel: {},
				rail: {
					keepShow: true,
				},
				bar: {
					hoverStyle: true,
					onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
					background: '#F5F5F5', //滚动条颜色
					opacity: 0.5, //滚动条透明度
					'overflow-y': 'hidden',
				},
			},
		}
	},
	components: {
		roleAdd,
		roleChange,
		roleTable,
	},
	mounted() {
		this.getProjectList()
	},
	methods: {
		showModal() {
			this.disabled = true
		},
		getData(data) {
			this.disabled = data
			this.getProjectList()
		},
    // 获取角色列表
		getProjectList() {
			let name = this.names
			this.$axios
				.get(this.api + '/role/showall', {
					params: {
						name,
					},
				})
				.then((res) => {
					if (res.code === 200) {
						this.data = res.data.list
					}
				})
		},
	},
}
</script>

<style scoped>
.home {
	height: 100%;
	padding: 15px;
	background: url(../../assets/bg01.jpg) no-repeat;
	background-size: 100% 100%;
}
.mianbao {
	height: 40px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.header {
	width: 100%;
	border: 1px solid #016ae0;
	border-radius: 8px;
	height: 60px;
	text-align: left;
	padding: 15px;
}
.main {
	height: calc(100% - 120px);
	border: 1px solid #016ae0;
	border-radius: 8px;
	margin-top: 20px;
	border-radius: 15px;
	padding: 10px;
}
.input {
	width: 180px;
	margin-right: 20px;
}
</style>
